'use client';
import cn from 'mxcn';
import BaseLink from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { ComponentProps, startTransition } from 'react';
import { useProgressBar } from '../global-progress';

/**
 * Wraps a Next.js Link component and shows a progress bar when clicked, as well
 * as add tailwind classes to allow for variants for active styling (both leaf
 * and parent)
 */
export default function Link(props: ComponentProps<typeof BaseLink>) {
  const pathname = usePathname();
  const href = String(
    props.href instanceof URL ? props.href.toString() : props.href
  );
  const isLeafActive = pathname === href;
  const isParentActive = pathname.startsWith(href);
  let progress = useProgressBar();
  let router = useRouter();

  return (
    <BaseLink
      {...props}
      onClick={(e) => {
        e.preventDefault();
        props.onClick?.(e);
        progress.start();

        startTransition(() => {
          router.push(href.toString());
          progress.done();
        });
      }}
      className={cn(
        props.className,
        isLeafActive && 'active-leaf-link',
        isParentActive && 'active-parent-link',
        (isLeafActive || isParentActive) && 'active-link'
      )}
    />
  );
}
